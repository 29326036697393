<template>
    <div>
        <!--begin::Content header-->
        <!--end::Content header-->

        <!--begin::Signin-->
        <div class="login-form login-signin">
            <div class="text-center mb-10 mb-lg-20">
                <h3 class="font-size-h1">Recupera Username</h3>
                <p class="text-muted font-weight-semi-bold">
                    Per assistenza tecnica scrivere a <a
                        href="mailto:supporto-tecnico@acsi.it">supporto-tecnico@acsi.it</a>.<br>
                    Un nostro tecnico sarà a vostra disposizione.
                </p>
            </div>

            <!--begin::Form-->
            <b-form v-if="!form_sent" class="form" @submit.stop.prevent="onSubmit">
                <div role="alert" class="alert alert-primary">
                    <div class="alert-text">
                        Utilizza il tuo <strong>Codice Fiscale</strong> e la tua <strong>e-mail</strong> per il recupero
                        delle tue credenziali.
                    </div>
                </div>

                <b-form-group id="codice-fiscale-group" label="" label-for="codice-fiscale">
                    <label for="login-username">Codice Fiscale *:</label>
                    <b-form-input class="form-control form-control-solid h-auto py-5 px-6" id="codice-fiscale"
                        name="codice-fiscale" v-model="$v.form.codice_fiscale.$model"
                        :state="validateState('codice_fiscale')" aria-describedby="codice_fiscale-live-feedback"
                        placeholder="Inserisci il tuo codice fiscale"></b-form-input>

                    <b-form-invalid-feedback id="codice-fiscale-live-feedback">
                        <template v-if="typeof form_errors['codice_fiscale'] !== 'undefined'">
                            <div v-for="error in form_errors['codice_fiscale']">{{ error }}</div>
                        </template>
                        <span v-else>Il codice fiscale è un campo obbligatorio.</span>
                    </b-form-invalid-feedback>
                </b-form-group>

                <b-form-group id="email-group" label="" label-for="email">
                    <label for="email">E-mail *:</label>
                    <b-form-input class="form-control form-control-solid h-auto py-5 px-6" type="email" id="email"
                        name="email" v-model="$v.form.email.$model" :state="validateState('email')"
                        aria-describedby="email-live-feedback"
                        placeholder="Inserisci il tuo indirizzo e-mail"></b-form-input>

                    <b-form-invalid-feedback id="email-live-feedback">
                        <template v-if="typeof form_errors['email'] !== 'undefined'">
                            <div v-for="error in form_errors['email']">{{ error }}</div>
                        </template>
                        <span v-else>L'indirizzo e-mail è un campo obbligatorio e deve essere un indirizzo
                            valido.</span>
                    </b-form-invalid-feedback>
                </b-form-group>

                <!--begin::Action-->
                <div class="form-group d-flex flex-wrap flex-center">
                    <button type="submit" ref="kt_recover_submit"
                        class="btn btn-primary font-weight-bold px-9 py-4 my-3 font-size-3 mx-4">
                        Invia
                    </button>
                    <button type="button" v-on:click.prevent="$router.push('login')"
                        class="btn btn-light-primary font-weight-bold px-9 py-4 my-3 font-size-3 mx-4">
                        Annulla
                    </button>
                </div>
                <!--end::Action-->

                <div class="form-group d-flex flex-wrap justify-content-center align-items-center">
                    <span class="font-weight-bold font-size-3 text-dark-60">
                        Torna alla pagina di
                    </span>
                    <router-link custom class="font-weight-bold font-size-3 ml-2" :to="{ name: 'login' }">
                        Login
                    </router-link>
                </div>
            </b-form>
            <!--end::Form-->

            <div v-if="form_sent">
                <div role="alert" class="alert alert-success">
                    <div class="alert-text">
                        La procedura di recupero username è avvenuata con successo.<br>
                        Riceverai a breve un messaggio di posta contenente il tuo username.<br>
                        Ti preghiamo di controllare anche la cartella di spam.
                    </div>
                </div>

                <button type="button" v-on:click.prevent="$router.push('/login')"
                    class="btn btn-primary btn-block font-weight-bold font-size-3">
                    Torna alla pagina di Login
                </button>
            </div>
        </div>
        <!--end::Signin-->
    </div>
</template>

<style lang="scss" scoped>
.spinner.spinner-right {
    padding-right: 3.5rem !important;
}
</style>

<script>
import { validationMixin } from "vuelidate";
import { email, minLength, required } from "vuelidate/lib/validators";
import ApiService from "@/core/services/api.service";

export default {
    mixins: [validationMixin],
    name: "recover-username",
    data() {
        return {
            form_sent: false,

            form_errors: {},

            form: {
                codice_fiscale: "",
                email: ""
            },
        };
    },
    validations: {
        form: {
            codice_fiscale: {
                required
            },
            email: {
                required,
                email
            }
        }
    },
    methods: {
        validateState(name) {
            const { $dirty, $error } = this.$v.form[name];
            return $dirty ? (typeof this.form_errors[name] !== "undefined" ? false : ($error ? false : null)) : null;
        },
        resetForm() {
            this.form = {
                codice_fiscale: null,
                email: null
            };

            this.$nextTick(() => {
                this.$v.$reset();
            });
        },
        onSubmit() {
            this.$v.form.$touch();
            if (this.$v.form.$anyError) {
                return;
            }

            const codice_fiscale = this.$v.form.codice_fiscale.$model;
            const email = this.$v.form.email.$model;

            // set spinner to submit button
            const submitButton = this.$refs["kt_recover_submit"];
            submitButton.classList.add("spinner", "spinner-light", "spinner-right");

            this.form_errors = {};

            let url = '/auth/recover-username';
            let data = { codice_fiscale: codice_fiscale, email: email };

            ApiService.post(url, data)
                .then((response) => {
                    if (response.data.status === 'OK') {
                        this.form_sent = true;
                    }

                    if (response.data.status === 'KO') {
                        this.form_errors = JSON.parse(JSON.stringify(response.data.errors));
                    }
                })
                .catch(({ response }) => {
                    if (response.status === 422) {
                        this.form_errors = JSON.parse(JSON.stringify(response.data.errors));
                    }
                    else {
                        console.log(response);
                    }
                });

            submitButton.classList.remove(
                "spinner",
                "spinner-light",
                "spinner-right"
            );
        }
    },
};
</script>
